<div style="height: 100%;">
  <section id="main_logo" class="d-none d-xl-block d-md-block brandBlueBackground">
    <div class="container">
      <nav class="navbar navbar-expand-lg p-0">
        <div class="navbar-brand d-inline">
          <a routerLink="/dashboard">
            <img src="/assets/images/logo/revenuedLogo.svg" class="img-fluid d-inline" alt="Revenued Logo" />
          </a>
        </div>
      </nav>
    </div>
  </section>

  <!-- <div style="background-color: #0040BA; height: 70px; width: 100%"></div>
  <div class="bluebackground1 bg-sub"></div>
  <div class="bluebackground"></div> -->

  <!-- <div class="brandBlueBackground bg-sub"></div> -->
  <!-- <div class="brandBlueBackground"></div> -->
  <div style="height: auto; padding-bottom: 0 !important;" class="commonBrandBg contentCard pricingCalcWrapper">
    <div id="wrapper">
      <div id="content">
        <div class="container p-0">
          <section id="ac_summary" class="d-flex justify-content-center ">
            <div class="container d-md-block d-lg-block d-xl-block" *ngIf="!disableCalc">
              <div class="card card1">
                <div class="summaryHeadingWrapper">
                  <h1 class="summary-heading text-uppercase primaryPageHead">
                    <span fxFlex></span>Understand Your Pricing<span fxFlex></span>
                  </h1>
                  <p *ngIf="offersListObj.length > 1" class="description primaryText50">
                    Select from following options or enter draw amount
                  </p>

                </div>


                <!-- begin:: Design option 4 -->
                <ng-container *ngIf="true">
                  <div class="offersGridParentWrapper4 mb-5">
                    <div class="loaderWrapper" *ngIf="loadingOffers">
                      <div class="loader">
                      </div>
                    </div>
                    <mat-tab-group mat-align-tabs="start" animationDuration="0ms"
                      (selectedTabChange)="onTabChanged($event);">
                      <mat-tab [label]="item.labelName" *ngFor="let item of offersListObj, let i = index">
                        <div class="tabContentWrapper">
                          <div class="shadowWrapper">
                            <div class="offersGridWrapper">
                              <ng-container *ngFor="let offerItem of offersListObj, let offerIndex = index">

                                <div class="offerGridItem" [id]="'offerGridItem' + offerIndex"
                                  [ngClass]="selectedOptionIndex== offerIndex ? 'selected' : ''">
                                  <div class="offerGridItemLayer">
                                    <!-- {{ offerItem.labelName }} -->
                                    <div class="infoRow" (click)="calcAndScrollToSchedule(item, (selectedOptionIndex))">
                                      <div class="padBox">
                                        <div class="groupItem" *ngIf="offerItem.limit">
                                          <h2 class="title">
                                            Available Spending Limit
                                          </h2>
                                          <p class="description">
                                            {{ offerItem.limit | currency }}
                                          </p>
                                        </div>

                                        <div class="groupItem">
                                          <h2 class="title">
                                            Factor Rate
                                          </h2>
                                          <p class="description">
                                            {{ offerItem.factor }}
                                          </p>
                                        </div>

                                        <div class="groupItem">
                                          <h2 class="title">
                                            Estimated Payback Period
                                          </h2>
                                          <p class="description">
                                            {{ offerItem.term }}
                                          </p>
                                        </div>

                                        <div class="groupItem">
                                          <h2 class="title">
                                            {{offerItem.collectionSchedule}} Payment
                                          </h2>
                                          <p class="description">
                                            {{ offerItem.dailyPayment | currency }}
                                          </p>
                                        </div>

                                      </div>
                                    </div>

                                    <!-- <div class="infoRow actionBtnWrapper" *ngIf="selectedOptionIndex== offerIndex">
                                    <button type="button" class="actionBtn" *ngIf="false"
                                      (click)="calcAndScrollToSchedule(item, (selectedOptionIndex))">
                                      See Schedule
                                    </button>
                                    <button type="button" class="actionBtn acceptBtn"
                                      (click)="openConfirmationDialog(item, (i))">
                                      Accept Offer
                                    </button>
                                  </div> -->
                                  </div>
                                </div>

                              </ng-container>

                            </div>
                          </div>

                          <div class="infoRow actionBtnWrapper"
                            [ngClass]="selectedOptionIndex == i ? 'selectedOption' + (i + 1) : ''">
                            <button type="button" class="actionBtn" *ngIf="false"
                              (click)="calcAndScrollToSchedule(item, (i))">
                              See Schedule
                            </button>
                            <button *ngIf="!offerAccepted" type="button" class="actionBtn acceptBtn"
                              (click)="openConfirmationDialog(item, (i))">
                              Accept Offer - {{ item.labelName }}
                            </button>
                          </div>
                        </div>






                        <div style="padding-left: 10px; margin-top: 30px;">
                          <p>Enter different draw amounts to see how your daily payment changes and also how you
                            can save money by paying off earlier.</p>
                        </div>
                        <div id="capture-pricing-cal-schedule">
                          <div
                            style="display: flex; padding: 0 20px 20px; padding-left: 10px; justify-content:space-between;"
                            class="inputs-container">

                            <div class="formFieldsRow">
                              <div class="form-inline input-gutter-cal">
                                <h6 class="m-0 formatText"
                                  style="text-transform:inherit !important;padding-right: 10px;">
                                  Draw Amount ($)</h6>
                                <span class="input-symbol-dollar">
                                  <input appNumbersOnly name="amount" [(ngModel)]="amount" [attr.max]="item.limit"
                                    (ngModelChange)="sanitizeAmount()" (keyup)="reCalcOfferDailyPayments()"
                                    class="form-control amount-input" /></span>
                              </div>
                              <div class="form-inline input-gutter-cal">
                                <h6 class="m-0 formatText"
                                  style="text-transform:inherit !important;padding-right: 10px;">
                                  Start Date</h6>
                                <nz-date-picker style="height: 50px; display: flex;" [(ngModel)]="selectedDate" [nzDisabledDate]="disablePastDates"
                                  (ngModelChange)="reCalcOfferDailyPayments()" class="form-control pricing-startdate-input">
                                </nz-date-picker>
                              </div>
                            </div>
                            <div *ngIf="!offerAccepted" class="screenshotWrapper capture-pricing-cal-schedule-exclude"
                              [ngClass]="{ 'disable-capture-pricing': !showScheduleTable }"
                              [title]="!showScheduleTable ? 'Screenshot is enabled only when viewing the schedule.' : ''"
                              (click)="showScheduleTable && captureScreenshot()">
                              <figure class="screenshotImg">
                                <img src="../../../assets/images/innerPages/icons/screenshot.svg"
                                  alt="Take a screenshot">
                              </figure>
                              <p class="screenshotTitle text-center">
                                Screenshot
                              </p>
                            </div>

                          </div>
                          <div class="sliderContainer priceSliderContainer d-none capture-pricing-cal-schedule-exclude">
                            <ngx-slider [(value)]="amount" (userChangeEnd)="reCalcOfferDailyPayments()"
                              [options]="sliderOptions" class="priceSlider"></ngx-slider>
                            <span class="default-slider-tick" (click)="defaultValue = 100"></span>
                          </div>
                          <div>
                            <div *ngIf="showScheduleTable" style="display: flex;" class="big-figures">
                              <div class="prepay-cal-daily-tag" [ngClass]="selectedOptionClass">
                                <h1 class="font-light text-white m-0" *ngIf="selectedOption == 'daily'">{{
                                  dailyPayment | currency }}</h1>
                                <h1 class="font-light text-white m-0" *ngIf="selectedOption == 'weekly'">{{
                                  weeklyPayment | currency }}</h1>
                                <h6 class="text-white m-0" *ngIf="selectedOption == 'daily'">Daily Payment</h6>
                                <h6 class="text-white m-0" *ngIf="selectedOption == 'weekly'">Weekly Payment
                                </h6>
                              </div>
                              <div class="prepay-cal-total-pay-tag" [ngClass]="selectedOptionClass">
                                <h1 class="font-light text-white m-0">{{ startingBalance | currency }}</h1>
                                <h6 class="text-white m-0">Purchased Amount</h6>
                              </div>
                            </div>
                            <div>
                              <p style="padding: 8px; margin-top: 12px; font-size: 80%; font-style: italic;">
                                <b>Note:</b> The tool below is for illustrative purposes only and does not
                                demonstrate a commitment
                                from either party. Revenued agrees to consider in good faith, any offer by
                                Merchant to repurchase
                                any of the outstanding Purchased Amounts with respect to any one or more
                                transactions, and will
                                disclose the price for any such repurchases that it is willing to accept (if
                                any) on the <a routerLink="/dashboard/paynow">Program Website</a>. However,
                                Revenued in its sole
                                discretion may accept or reject any offer to Repurchase.
                              </p>
                            </div>
                            <br />
                            <div #scheduleTable [ngClass]="selectedOptionClass" class="scheduleListTable">
                              <!-- <div class="optionTitleWrapper capture-pricing-cal-schedule-exclude" *ngIf="showScheduleTable">
                          <h3 class="title">
                            Schedule for
                            <label for="priceOptions">:</label>
                            <select name="pricingOptions" id="priceOptions" class="pricingOptionsList"
                              [(ngModel)]="selectedOptionIndex" (change)="onOptionChange($event)">
                              <option *ngFor="let offer of offersListObj; let i = index;" [value]="i">{{ "Option " +
                                (i+1)
                                }}</option>
                            </select>
                          </h3>
                        </div> -->
                              <nz-table *ngIf="showScheduleTable" class="prepay-cal" #basicTable [nzData]="payNowRows"
                                [nzHideOnSinglePage]="true" nzNoResult="Please provide both the draw amount and start date to see the payment schedule.">
                                <thead>
                                  <tr>
                                    <th><span nz-tooltip nzTooltipTitle=""># of
                                        Periods</span></th>
                                    <th><span nz-tooltip nzTooltipTitle="">Biz Date to Pay
                                        Now</span></th>
                                    <th><span nz-tooltip nzTooltipTitle="">Sum of
                                        Payments</span></th>
                                    <th><span nz-tooltip nzTooltipTitle="">Remaining
                                        Balance</span></th>
                                    <th><span nz-tooltip nzTooltipTitle="">PayNow
                                        Balance</span></th>
                                    <th><span nz-tooltip nzTooltipTitle="">Total Paid</span>
                                    </th>
                                    <th><span nz-tooltip nzTooltipTitle="">Total Cost of
                                        Capital</span></th>
                                    <th><span nz-tooltip nzTooltipTitle="">Total
                                        Savings</span></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let data of basicTable.data" class="prepay-cal-row">
                                    <td>{{ selectedOption == 'daily' ? data.days: data.days/5 }}</td>
                                    <td>{{data.date | date}}</td>
                                    <td>{{data.sumOfDallies | currency }}</td>
                                    <td>{{data.remainingBalance | currency }}</td>
                                    <td>{{data.payNowBalance | currency}}</td>
                                    <td>{{data.totalPaid | currency}}</td>
                                    <td>{{data.totalCostCapital | currency}}</td>
                                    <td>{{data.totalSavings | currency}}</td>
                                  </tr>
                                </tbody>
                              </nz-table>
                              <nz-table *ngIf="showScheduleTable" class="mob-prepay-cal" #mobilePricingTable
                                [nzData]="payNowRows" [nzHideOnSinglePage]="true"
                                nzNoResult="Please provide both the draw amount and start date to see the payment schedule.">
                                <thead>
                                  <tr>
                                    <th nzWidth=""><span nz-tooltip nzTooltipTitle=""># of
                                        Periods</span></th>
                                    <th nzWidth="130px"><span nz-tooltip nzTooltipTitle="">Biz Date to Pay
                                        Now</span></th>
                                    <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Sum of
                                        Payments</span></th>
                                    <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Remaining
                                        Balance</span></th>
                                    <th nzWidth=""><span nz-tooltip nzTooltipTitle="">PayNow
                                        Balance</span></th>
                                    <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Total Paid</span>
                                    </th>
                                    <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Total Cost of
                                        Capital</span></th>
                                    <th nzWidth=""><span nz-tooltip nzTooltipTitle="">Total
                                        Savings</span></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let data of mobilePricingTable.data" class="prepay-cal-row">
                                    <td>{{ selectedOption == 'daily' ? data.days: data.days/5 }}</td>
                                    <td>{{data.date | date}}</td>
                                    <td>{{data.sumOfDallies | currency }}</td>
                                    <td>{{data.remainingBalance | currency }}</td>
                                    <td>{{data.payNowBalance | currency}}</td>
                                    <td>{{data.totalPaid | currency}}</td>
                                    <td>{{data.totalCostCapital | currency}}</td>
                                    <td>{{data.totalSavings | currency}}</td>
                                  </tr>
                                </tbody>
                              </nz-table>
                            </div>
                          </div>
                        </div>

                      </mat-tab>
                    </mat-tab-group>
                  </div>

                </ng-container>
                <!-- end:: Design option 4 -->






              </div>
            </div>
            <div class="container d-md-block d-lg-block d-xl-block" *ngIf="disableCalc">
              <div class="card card1">
                <div class="summaryHeadingWrapper">
                  <h1 class="summary-heading primaryPageHead">
                    <span fxFlex></span> This tool is currently unavailable. Please contact customer service<span
                      fxFlex></span>
                  </h1>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  <section id="footer">
    <app-footer noAuth="true"></app-footer>
  </section>
  <div class="md-hide splash-screen">
    <h4 style="color: #fff; font-weight: 300;">For a better experience</h4>
    <h2 style="color: #fff;">Please switch to portrait orientation.</h2>
  </div>
</div>
