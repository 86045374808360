/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable arrow-body-style */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit, Inject, Optional, ViewChild, ViewEncapsulation, ElementRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment-business-days'; //'moment'
import { AccountService } from 'src/app/core/account.service';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PriceConfirmationComponent } from '../../../app/dashboard/prepay-cal/price-confirmation/price-confirmation.component';
import html2canvas from 'html2canvas';


export interface PayNowElement {
  days: number;
  sumOfDallies: number;
  remainingBalance: number;
  payNowBalance: number;
  totalPaid: number;
  weeksUntilPayNow: number;
  totalCostCapital: number;
  totalSavings: number;
  factor: String;
  date: String;
}

var payNowRows: PayNowElement[] = [];

@Component({
  selector: 'app-pricing-cal',
  templateUrl: './pricing-cal.component.html',
  styleUrls: ['./pricing-cal.component.css',],
  encapsulation: ViewEncapsulation.None,
})
export class PricingCalComponent implements OnInit {
  searchText: any;
  totalCount = -1;
  Closed = -1;
  Inprogress = -1;
  Open = -1;
  selectedOption = 'daily';
  weeklyPayment = 0;
  startingBalance = 0;
  dailyPayment = 0;
  oppId: string;
  disableCalc = false;
  term: any;
  loadingOffers = true;
  showScheduleTable = false;
  offerAccepted = false;
  factor;
  amount: any = 20000;
  configs = {};
  todayDate = new Date();
  maxDate = new Date('12/31/2025');
  selectedDate = new Date();
  // dataSource;
  payNowRows;
  forminput = { term: '', factor: '' };
  offersObjLength = 0;

  defaultValue = 100;
  sliderOptions: Options = {
    floor: 0,
    ceil: 195313,
    step: 1,
    showTicks: true,
    showTicksValues: true,
    ticksArray: [0, 48850, 97700, 146550, 195313],
    ticksTooltip: (val: number): string => {
      return `$${val.toLocaleString('en-US')}`;
    },
    translate: (value: number, label: LabelType): string => {
      return '$' + value;
    }
  };
  @ViewChild('scheduleTable') scheduleTable: ElementRef;
  invalidOppId: boolean;

  getDailyPayment(factor: any, term: any) {
    // this.startingBalance = this.amount * this.factor;
    // this.dailyPayment = this.startingBalance / this.term;
    // this.weeklyPayment = this.dailyPayment * 5;
    const startingBalanceLoc = (this.amount * factor).toFixed(2);
    return (parseFloat(startingBalanceLoc) / term);
  }

  offersListObj = [
    // {
    //   offerId: 2,
    //   labelName: 'Option 1',
    //   type: 'Standard',
    //   maxLimit: 20000,
    //   factor: 1.99,
    //   term: 240,
    //   collectionSchedule: 'Daily',
    //   purchasedAmount: 10000,
    //   dailyPayment: this.getDailyPayment(1.99, 240),
    //   scheduleLink: 'https://www.google.com'
    // },
    // {
    //   offerId: 1,
    //   labelName: 'Option 2',
    //   type: 'Min',
    //   maxLimit: 100000,
    //   factor: 1.39,
    //   term: 140,
    //   collectionSchedule: 'Daily',
    //   purchasedAmount: 100000,
    //   dailyPayment: this.getDailyPayment(1.39, 140),
    //   scheduleLink: 'https://www.google.com'
    // },
    // {
    //   offerId: 3,
    //   labelName: 'Option 3',
    //   type: 'Max',
    //   maxLimit: 195313,
    //   factor: 1.99,
    //   term: 210,
    //   collectionSchedule: 'Daily',
    //   purchasedAmount: 10000,
    //   dailyPayment: this.getDailyPayment(1.99, 210),
    //   scheduleLink: 'https://www.google.com'
    // }
  ];

  takeScreenshot() {
    console.log('Taking Screenshot');
  }

  selectedOptionIndex = undefined;
  selectedOptionClass = '';

  sanitizeAmount() {
    // Remove all non-digit characters
    const sanitizedValue = this.amount.toString().replace(/\D/g, '');
    this.amount = sanitizedValue ? parseInt(sanitizedValue, 10) : 0;

    this.offersListObj[this.selectedOptionIndex].dailyPayment = this.getDailyPayment(parseFloat(this.offersListObj[this.selectedOptionIndex].factor), parseInt(this.offersListObj[this.selectedOptionIndex].term, 10));
  }

  reCalcOfferDailyPayments() {
    this.sanitizeAmount(); // Ensure the amount is sanitized before recalculating
    // Ensure the amount does not exceed the limit
    const maxLimit = this.offersListObj[this.selectedOptionIndex]?.limit;
    if (this.amount > maxLimit) {
      this.amount = maxLimit;
    }
    const currentOffer = this.offersListObj[this.selectedOptionIndex];
    this.offersListObj[this.selectedOptionIndex].dailyPayment = this.getDailyPayment(parseFloat(this.offersListObj[this.selectedOptionIndex].factor), parseInt(this.offersListObj[this.selectedOptionIndex].term, 10));

    // if (currentOffer && amountInt <= currentOffer.limit) {
    this.calcAndScrollToSchedule(currentOffer, this.selectedOptionIndex, false);
    // } else {
    //   if (nextValidOffer) {
    //     this.calcAndScrollToSchedule(nextValidOffer, this.offersListObj.indexOf(nextValidOffer), false);
    //   } else {
    //     this.showScheduleTable = false;
    //     this.selectedOptionIndex = null;
    //   }
    // }
  }

  calcAndScrollToSchedule(offerObj, optionNumber, scrollToSchedule = true) {
    // console.log(optionNumber.classList);
    this.selectedOptionIndex = parseInt(optionNumber, 10);
    this.showScheduleTable = true;
    if (scrollToSchedule) {
      console.log("Scrolling to schedule table" + this.selectedOptionIndex);
      this.scheduleTable.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
    this.term = parseInt(offerObj.term, 10);
    this.selectedOption = offerObj.collectionSchedule.toLowerCase() === 'daily' ? 'daily' : 'weekly';
    this.factor = parseFloat(offerObj.factor);
    this.selectedOptionClass = `selectedOption-${this.selectedOptionIndex}`;
    // this.activeIndex = this.selectedOptionIndex;
    console.log("selectedOptionClass", this.selectedOptionClass);

    setTimeout(() => {
      this.calculateTable('');
    }, 250);
  }

  onOptionChange(event: any) {
    console.log(event);
    const value = parseInt(event.target.value, 10) - 0;
    this.calcAndScrollToSchedule(this.offersListObj[this.selectedOptionIndex], this.selectedOptionIndex);
  }


  isChildOfferModalLoading = false; // Track loading state
  modalRef: BsModalRef;
  openConfirmationDialog(offerObj, optionNumber) {
    // console.log(offerObj);
    // console.log(optionNumber);
    this.selectedOptionIndex = optionNumber;
    this.modalRef = this.modalService.show(PriceConfirmationComponent,
      {
        class: `modal-dialog-centered modal-lg offerConfirmationModal selectedOption-${this.selectedOptionIndex}`,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: {
          offerData: offerObj,
          oppId: this.oppId,
          optionIndex: optionNumber,
        }

      });

    // const modalInstance = this.modalRef.content as PriceConfirmationComponent;
    // if (modalInstance.loadingChange) {
    //   modalInstance.loadingChange.subscribe((loading: boolean) => {
    //     this.isChildOfferModalLoading = loading;
    //     // console.log(" this.isChildOfferModalLoading: " + this.isChildOfferModalLoading);
    //   });
    // }

    // this.modalRef.onHide.subscribe(() => {
    //   console.log("I am here onHide: " + this.isChildOfferModalLoading);
    //   if (this.isChildOfferModalLoading) {
    //     console.log("I am here onHideFunction: " + this.isChildOfferModalLoading);
    //     return false;
    //   }
    // });

  }

  captureScreenshot() {
    const element = document.getElementById('capture-pricing-cal-schedule'); // Replace with your element's ID

    if (element) {
      // Create a wrapper div with padding
      const wrapper = document.createElement('div');
      wrapper.style.padding = '20px'; // Add your desired padding
      wrapper.style.width = '1440px'; // Add your desired padding
      wrapper.style.backgroundColor = 'white'; // Optional: set background color
      wrapper.style.position = 'absolute'; // Prevent layout issues
      wrapper.style.left = '-9999px'; // Move it off-screen to avoid user interaction
      wrapper.appendChild(element.cloneNode(true) as HTMLElement); // Clone the target element

      document.body.appendChild(wrapper); // Add wrapper to DOM
      // Apply CSS to the input with class 'amount-input' to show in large font
      const amountInput = wrapper.querySelector('.amount-input') as HTMLElement;
      const dateInput = wrapper.querySelector('.pricing-startdate-input input') as HTMLElement;
      if (amountInput) {
        amountInput.style.fontSize = '22px'; // Set the desired large font size
        amountInput.style.paddingTop = '14px';
      }
      if (dateInput) {
        dateInput.style.fontSize = '22px'; // Set the desired large font size
        dateInput.style.paddingTop = '14px';
      }
      html2canvas(wrapper, {
        ignoreElements: (el) => el.classList.contains('capture-pricing-cal-schedule-exclude')
      }).then((canvas) => {
        // Convert the canvas to an image
        const image = canvas.toDataURL('image/png');
        // Remove the wrapper from DOM
        document.body.removeChild(wrapper);
        // Create a link to download the image
        const link = document.createElement('a');
        link.href = image;
        const optionNumber = this.selectedOptionIndex + 1;
        const selectedOffer = this.offersListObj[this.selectedOptionIndex];
        const totAmount = this.amount * selectedOffer.factor;
        const dailyAmount = (totAmount / selectedOffer.term).toFixed(2);
        const timestamp = new Date().toLocaleString('en-US', { hour12: false }).replace(',', '').replace(/\//g, '-').replace(/:/g, '-').replace(' ', '_');
        link.download = `Option${optionNumber}_${dailyAmount}_${timestamp}.png`;
        link.click();
      }).catch((error) => {
        console.error('Error capturing screenshot:', error);
        // Remove the wrapper from DOM
        document.body.removeChild(wrapper);
      });
    } else {
      console.error('Element not found!');
    }
  }

  displayedColumns: string[] = [
    'bizDays',
    'bizDate',
    'sumDallies',
    'pnb',
    'remainBal',
    'totalPaid',
    'totCostCap',
    'factor',
    'totSavings',
  ];

  constructor(public router: Router, private accountService: AccountService, private activatedRoute: ActivatedRoute, private modalService: BsModalService) { }


  checkOfferCount(offersCount) {
    console.log("I am here Offers count: " + offersCount);
    setTimeout(() => {
      let selectedOfferTab = document.querySelector('.offersGridParentWrapper4 .mat-tab-header') as HTMLElement;
      let selectedOfferTabContent = document.querySelector('.offersGridParentWrapper4 .tabContentWrapper') as HTMLElement;
      let selectedOfferItem = document.querySelector('.offersGridParentWrapper4 .offersGridWrapper') as HTMLElement;
      if (offersCount == 1) {
        // console.log("I am here 2");
        selectedOfferTab.classList.add('singleOfferTab');
        selectedOfferTabContent.classList.add('singleOfferTabContent');
        // console.log("I am here selectedOfferItem: " + selectedOfferItem);
        selectedOfferItem.classList.remove('multiOffers');
        selectedOfferItem.classList.add('singleOffer');
      }
      else {
        // console.log("I am here 3");
        // console.log("I am here Offers count: " + offersCount);
        // console.log("I am here selectedOfferItem: " + selectedOfferItem);
        selectedOfferTab.classList.remove('singleOfferTab');
        selectedOfferTabContent.classList.remove('singleOfferTabContent');
        selectedOfferItem.classList.add('multiOffers');
        selectedOfferItem.classList.remove('singleOffer');
      }
    }, 0);
  }

  ngOnInit(): void {
    let oppId = '';
    this.activatedRoute.queryParams.subscribe(
      (params: any) => {
        if (!params || !params.oppId) {
          this.invalidOppId = true;
        }
        oppId = params.oppId;
        this.oppId = oppId;
      }
    );
    this.selectedOption = 'daily';
    this.graceWindow = localStorage.getItem('Grace_Window_Days') ? parseInt(localStorage.getItem('Grace_Window_Days'), 10) : 22;
    this.postGracePremium = localStorage.getItem('Post_Grace_Premium') ? parseFloat(localStorage.getItem('Post_Grace_Premium')) : 1.085;
    const holidayListArray = localStorage.getItem('US_HOLIDAYS')?.split?.(',');
    // let oldTerm = !localStorage.getItem("prepayCalTerm") ? null : parseInt(localStorage.getItem("prepayCalTerm"));
    // let oldFactor = !localStorage.getItem("prepayCalFactor") ? null : parseFloat(localStorage.getItem("prepayCalFactor"));
    // let oldAmount = !localStorage.getItem("prepayCalAmount") ? null : parseFloat(localStorage.getItem("prepayCalAmount"));
    // if (!localStorage.getItem('collSchedType') || !oldFactor || !oldTerm) {
    this.accountService.getTermAndFactorDirect(oppId).subscribe((res) => {
      res = JSON.parse(res);
      this.term = parseInt(res.term, 10);
      this.selectedOption = res.collSchedType ? res.collSchedType?.toLowerCase() : 'daily';
      this.factor = parseFloat(res.factor);
      this.amount = res.offers?.[0]?.maxLimit || 10000;
      this.disableCalc = res.funded === true;
      this.offerAccepted = res.accepted === true;
      this.offersListObj = res.offers.map((offer: any, index: number) => ({
        offerId: offer.offerId,
        labelName: `Option ${index + 1}`,
        type: 'Standard',
        limit: parseFloat(offer.maxLimit),
        factor: parseFloat(offer.factor),
        term: parseInt(offer.term, 10),
        collectionSchedule: this.selectedOption,
        purchasedAmount: this.amount,
        dailyPayment: this.getDailyPayment(parseFloat(offer.factor), parseInt(offer.term, 10)),
        scheduleLink: 'https://www.google.com'
      }));

      this.graceWindow = res.daysGrace;
      this.postGracePremium = parseFloat(res.postPremium);
      this.calcAndScrollToSchedule(this.offersListObj[0], 0, false);
      this.loadingOffers = false;
      this.offersObjLength = this.offersListObj.length;
      console.log("I am in ngInit: Offers count: " + this.offersObjLength);
      this.checkOfferCount(this.offersObjLength);
      // localStorage.setItem("prepayCalTerm", this.term);
      // localStorage.setItem("collSchedType", this.selectedOption);
      // localStorage.setItem("prepayCalFactor", this.factor);
    }, (error) => {
      this.term = 140;
      this.factor = 1.39;
      // localStorage.setItem("prepayCalTerm", this.term);
      // localStorage.setItem("collSchedType", this.selectedOption);
      // localStorage.setItem("prepayCalFactor", this.factor);
    });
    // } else {
    //   this.term = oldTerm;
    //   this.factor = oldFactor;
    //   if (oldAmount) {
    //     this.amount = oldAmount;
    //     this.selectedDate = !localStorage.getItem("prepayCalDate") ? new Date() : new Date(localStorage.getItem("prepayCalDate"));
    //     setTimeout(() => {
    //       this.calculateTable("");
    //     }, 0);
    //   }
    // }
    this.holidayList = holidayListArray.map((d: string) => moment(d, 'DD/MM/YYYY'));


    moment.updateLocale('us', {
      holidays: holidayListArray,
      holidayFormat: 'DD/MM/YYYY',
    });
  }

  ngAfterViewInit() {
    this.checkOfferCount(this.offersObjLength);
  }

  graceWindow = 22;
  repoBase = 1;
  postGraceRepoBase = 1;
  postGracePremium = 1.085;
  holidayList: any;

  calculateTable(filterValue: string): void {
    if (
      this.term &&
      this.factor &&
      this.amount &&
      !isNaN(this.term) &&
      !isNaN(this.factor) &&
      !isNaN(this.amount) &&
      this.term > 0 &&
      this.factor > 0 &&
      this.amount > 0 &&
      this.selectedDate
    ) {
      console.log(this.term);
      console.log(this.factor);
      console.log(this.amount);
      // localStorage.setItem("prepayCalAmount", this.amount);
      // localStorage.setItem("prepayCalDate", moment(this.selectedDate).toString());
      this.repoBase = 1 / this.factor;
      this.postGraceRepoBase = this.postGracePremium / this.factor;

      console.log(this.repoBase);
      var dayIncrement = 5;
      this.startingBalance = this.amount * this.factor;
      this.dailyPayment = this.startingBalance / this.term;
      this.weeklyPayment = this.dailyPayment * 5;
      payNowRows = [];

      for (let i = 5; i <= this.term; i += 5) {
        console.log('push');
        const dailyTally = i * this.dailyPayment;
        const remainBal = this.startingBalance - dailyTally;
        const payNow =
          i > this.graceWindow ? remainBal * this.postGraceRepoBase : remainBal * this.repoBase;
        const totalPaid = payNow + dailyTally;
        const totalCostCapital = totalPaid - this.amount;
        const totalSavings = remainBal - payNow;
        payNowRows.push({
          days: i,
          date: moment(this.selectedDate).businessAdd(i).toString(),
          sumOfDallies: dailyTally,
          remainingBalance: remainBal,
          payNowBalance: payNow,
          totalPaid,
          weeksUntilPayNow: 1,
          totalCostCapital,
          totalSavings,
          factor: (1 + totalCostCapital / this.amount).toFixed(2),
        });
      }
      this.payNowRows = [...payNowRows];
    } else {
      this.payNowRows = [];
    }
  }

  onTabChanged(e: any) {
    console.log(e);
    if (this.amount > this.offersListObj[e.index].limit) {
      this.amount = this.offersListObj[e.index].limit;
    }
    this.offersListObj[e.index].dailyPayment = this.getDailyPayment(parseFloat(this.offersListObj[e.index].factor), parseInt(this.offersListObj[e.index].term, 10));
    this.calcAndScrollToSchedule(this.offersListObj[e.index], e.index, false);

    if (window.innerWidth < 768) {
      const offerGridItemSelected = document.querySelector('#offerGridItem' + e.index) as HTMLElement;
      offerGridItemSelected.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });

    }
  }

  goToCashDraw() {
    this.router.navigate(['/dashboard/cash-draw']);
  }

  onOptionChanged(event: any) {
    this.selectedOption = event.value;
  }

  weekendsDatesFilter = (d: Date): boolean => {
    /* Prevent Saturday and Sunday for select. */
    return (
      d && moment(d).isBusinessDay() && !this.holidayList.find((h: any) => moment(d).isSame(moment(h)))
    );
  };

  disablePastDates = (current: Date): boolean => {
    // Disable all dates before today
    return current && current.getTime() < new Date().setHours(0, 0, 0, 0);
  };
}
